import React, { useEffect, useState } from 'react';


const SpinnerLoader = () => {
  const [text, setText] = useState('');
  const [showImg, setShowImg] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImg(false);
      setText('I waited for 3 seconds to be loaded, did you see the spinner?');
    }, 3000);
    
    return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
  }, []);

  return (
    <div className="spinner-container">
      {showImg ? (
        <img src='./Spinner.svg' alt='Loading spinner' className="spinner" />
      ) : (
        <h3>{text}</h3>
      )}
    </div>
  );
};

export default SpinnerLoader;