import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import account from '../images/icon/account-icon.png';
import wishlist from '../images/icon/whishlist-icon.png';  // Fixed typo from 'whishlist' to 'wishlist'
import cart from '../images/icon/cart-icon.png';
import logo from '../images/logo/kupto-final logo-2.png';
import cartegory from '../images/icon/category-01.png';  // Fixed typo from 'cartegory' to 'category'
import { useDispatch, useSelector } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { filterProducts, getAllProducts, setSearchTerm, selectUniqueCategories } from '../app/features/products/productSlice';
import DropdownItem from './DropdownItem'; // Import the updated DropdownItem component
import './SearchComponent.css';
import { TbCategoryFilled } from "react-icons/tb";
import { fetchCategories, selectCategories } from '../app/features/category/categorySlice';
import { IoPersonCircleOutline } from "react-icons/io5";







const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartState = useSelector(state => state?.auth?.cartProduct) || [];
  const authState = useSelector(state => state?.auth);
  const productState = useSelector(state => state?.product?.products) || [];
  const categories = useSelector(selectCategories);
  const uniqueCategories = useSelector(state => selectUniqueCategories(state, categories));
  console.log(categories)
  const [productOpt, setProductOpt] = useState([]);
  const [paginate, setPaginate] = useState(true);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTermState] = useState('');
  let menuRef = useRef();

  useEffect(() => {
    if (!productState?.length) {
      dispatch(getAllProducts());
    }
  }, [dispatch, productState]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const data = productState?.map((item) => ({
      id: item._id,
      prod: item._id,
      name: item.title,
    }));
    setProductOpt(data);
  }, [productState]);

  useEffect(() => {
    const sum = Array.isArray(cartState) ? cartState.reduce((acc, item) => acc + Number(item.quantity), 0) : 0;
    setTotal(sum);
  }, [cartState]);

  useEffect(() => {
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };



  const handleSearch = selected => {
    if (selected.length > 0) {
      const searchTerm = selected[0].name;
      setSearchTermState(searchTerm);
      dispatch(setSearchTerm(searchTerm)); // Update the search term in Redux state
      dispatch(filterProducts({ searchTerm })); // Ensure filterProducts is correctly applied
      navigate(`/product?search=${encodeURIComponent(searchTerm)}`); // Navigate with search term
    }
  };

  const handleSearchClick = () => {
    if (searchTerm.trim()) {
      dispatch(setSearchTerm(searchTerm)); // Update the search term in Redux state
      dispatch(filterProducts({ searchTerm })); // Ensure filterProducts is correctly applied
      navigate(`/product?search=${encodeURIComponent(searchTerm)}`); // Navigate with search term
    }
  };

  

  
  
  return (
    <>
   {/* <header className='mobile-header'>
      <div className='d-flex align-items-center'>
    <div className='dis-cat'>
                <div className='menu-container' ref={menuRef}>
                  <div className='menu-trigger' onClick={()=>{setOpen(!open)}}>
      
                    <button className='btn-drop'>

                      <TbCategoryFilled className='cat-iconn'/>
                      </button>
                  </div>

                  <div className={`dropdown-menu ${open? 'active' : 'inactive'}`}>
                  <ul>
                  {categories.map((category, index) => (
                      <DropdownItem key={index} category={category} />
                    ))}
                      </ul>
                  </div>

                </div>
              </div>

      <div>
        <Link to='/' className='logo-11'>
              <img src={logo} className='loggo' alt=''/>
            </Link>
      </div>
      </div>

      <div className='d-flex align-items-center justify-content-center gap-15'>
      <div>
      <Link 
        to={authState?.user ? '/my-profile' : '/login'} 
        className={({ isActive }) => isActive ? 'navbar-mobi active' : 'navbar-mobi'}
      >
        <IoPersonCircleOutline className='fs-mob'/>
        
      </Link>
      </div>
      <div>
      <Link to='/cart' className='display-linkk'>
                  <img src={cart}  alt='' className='cart-ic' />
                  <div className='d-flex align-items-center'>
                    <span className='badge'>{cartState?.length? cartState?.length : 0}</span>
                  </div>
                  
                  </Link>
      </div>
      </div>


    </header>*/}
    <header className='search-unit'>
    <div className='input-group p-t'>
                <Typeahead
                  id="search-products"
                  onChange={handleSearch}
                  options={productOpt}
                  paginate={paginate}
                  labelKey='name'
                  minLength={2}
                  placeholder="Search on Kupto..."
                  className="typeahead-custom"
                  onInputChange={(text) => setSearchTermState(text)}
                />
                <span className='input-group-text' id='basic-addon2' onClick={handleSearchClick}>
                  <BsSearch className='in-search'/>
                </span>

                

                
              </div>
    </header>
    <header className='header-top-strip'>
      <div className='.container-xxl'>
        <div className='row'>
          <div className='col-6'>
          <p className='text-end text-white mb-0'>
              Need Help: <a className='text-white' href="tel:+256 704116349">+256 704116349</a></p>
          </div>
          <div className='col-7'>
           

            <div className='link-strip'>
              <Link to='/terms-and-conditions'>
              <p>Terms and Conditions</p>
              </Link>
            </div>

            <div className='link-strip'>
              <Link to='/help'>
              <p>Help</p>
              </Link>
            </div>
            
          </div>

        </div>

      </div>
    </header>
    
    <header className='header-upper py-3'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-2'>
          <div className='dis-cat'>
                <div className='menu-container' ref={menuRef}>
                  <div className='menu-trigger' onClick={()=>{setOpen(!open)}}>
      
                    <button className='btn-drop'>

                      <TbCategoryFilled className='cat-icon'/>
                      </button>
                  </div>

                  <div className={`dropdown-menu ${open? 'active' : 'inactive'}`}>
                  <ul>
                  {categories.map((category, index) => (
                      <DropdownItem key={index} category={category} />
                    ))}
                      </ul>
                  </div>

                </div>
              </div>
            
           <h1>
            <Link to='/' className='logo-1'>
              <img src={logo} width={150} alt=''/>
            </Link>
           </h1>
          </div>
          <div className='col-5'>
          <div className='input-group'>
                <Typeahead
                  id="search-products"
                  onChange={handleSearch}
                  options={productOpt}
                  paginate={paginate}
                  labelKey='name'
                  minLength={2}
                  placeholder="Search on Kupto..."
                  className="typeahead-custom"
                  onInputChange={(text) => setSearchTermState(text)}
                />
                <span className='input-group-text' id='basic-addon2' onClick={handleSearchClick}>
                  <BsSearch className='in-search'/>
                </span>

                

                
              </div>
          
          </div>
          <div className='col-5'>
            <div className='header-upper-links'>
              <div>
                <Link to={authState?.user ? '/my-profile' : '/login'} className='display-link n-mb'>
                    <img src={account} width={25} alt='Account' />
                    <p>{authState?.user ? `Welcome ${authState?.user?.firstname}` : 'My Account'}</p>
                  </Link>
              </div>
              <div>
                <Link to='/Whishlist' className='display-link n-mb'>
                  <img src={wishlist} width={25} alt='' />
                  <p>Whishlist</p>
                  </Link>
              </div>
              <div>
                <Link to='/cart' className='display-link w-mb'>
                  <img src={cart} width={25} alt='' className='cart-ic' />
                  <div className='d-flex'>
                    <span className='badge'>{cartState?.length? cartState?.length : 0}</span>
                  </div>
                  
                  </Link>
              </div>
              
            </div>
          </div>
        </div>
      </div>

    </header>

    <header className='header-bottom py-3'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-12'>
            <div className='menu-bottom'>

              <div>
                <div className='menu-container' ref={menuRef}>
                  <div className='menu-trigger' onClick={()=>{setOpen(!open)}}>
      
                    <button className='btn-drop'>
                      <img src={cartegory} width={25} alt='' />
                      SHOP CATEGORIES
                      </button>
                  </div>

                  <div className={`dropdown-menu ${open? 'active' : 'inactive'}`}>
                  <ul>
                  {categories.map((category, index) => (
                      <DropdownItem key={index} category={category} />
                    ))}
                      </ul>
                  </div>

                </div>
              </div>

              <div className='menu-links'>
                <div className='link'>
                  <NavLink to='/'>Home</NavLink>
                  <NavLink to='/product'>Our Store</NavLink>
                  <NavLink to='/my-orders'>My Orders</NavLink>
                  <NavLink to='/About us'>About Us</NavLink>
                  <NavLink to='/Contact'>Contact</NavLink>
                  <button onClick={handleLogout} className='logout-b' type='button'>LOGOUT</button>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </header>

    <header className='col-none'>
    <div className='col-5'>
    <div className='input-group'>
                <Typeahead
                  id="search-products"
                  onChange={handleSearch}
                  options={productOpt}
                  paginate={paginate}
                  labelKey='name'
                  minLength={2}
                  placeholder="Search on Kupto..."
                  className="typeahead-custom"
                />
                <span className='input-group-text' id='basic-addon2'>
                  <BsSearch onClick={handleSearch} />
                </span>
              </div>
            </div>
            {/*<div className='input-group'>
              <input type='text' className='form-control'
              placeholder="Search Product..."
              aria-label="Search Product..."
              aria-describedby="basic-addon2"
              />
              <span className='input-group-text' id='basic-addon2'>
                <BsSearch />
              </span>
            </div> */}
            
          
          </header>

    
    </>
  )
}





export default Header;


/*<header className='col-none'>
        <div className='col-5'>
          <div className='input-group'>
            <Typeahead
              id="search-products-mobile"
              onChange={selected => {
                if (selected.length > 0) {
                  navigate(`/product/${selected[0].prod}`);
                  dispatch(getAProduct(selected[0].prod));
                }
              }}
              options={productOpt}
              paginate={paginate}
              labelKey='name'
              minLength={2}
              placeholder="Search on Kupto..."
              className="typeahead-custom"
              renderMenuItemChildren={(option, props) => (
                <div key={option.id} className="dropdown-item-custom">
                  <span>{option.name}</span>
                </div>
              )}
            />
            <span className='input-group-text' id='basic-addon2'>
              <BsSearch />
            </span>
          </div>
        </div>
      </header> */
