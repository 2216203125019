import React, { useEffect, useState } from 'react';
import { Link, useLocation, matchPath, useNavigate } from 'react-router-dom';
import { CiShoppingCart } from "react-icons/ci";
import { FaRegHeart } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addProdToCart, getUserCart } from '../app/features/user/userSlice';
import { getAProduct, getAllProducts, addToWishlists } from '../app/features/products/productSlice';
import { MdLocalPhone } from "react-icons/md";



const CartAddBar = ({ color, size, quantity, uploadedFiles }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alreadyAdded, setAlreadyAdded] = useState(false);

  const productState = useSelector(state => state.product.singleProduct);
  console.log(productState);
  const getProductId = location.pathname.split('/')[2];
  const cartState = useSelector(state => state.auth.cartProduct);

  useEffect(() => {
    dispatch(getAProduct(getProductId));
    dispatch(getUserCart());
    dispatch(getAllProducts());
  }, [dispatch, getProductId]);

  useEffect(() => {
    if (cartState?.some(item => item.productId._id === getProductId)) {
      setAlreadyAdded(true);
    }
  }, [cartState, getProductId]);

  const uploadCart = () => {
    if (productState?.category === 'Clothes' && !size) {
      toast.error('Please Choose Size');
      return;
    }
    if (!color) {
      toast.error('Please Choose Color');
      return;
    }
    dispatch(addProdToCart({
      productId: productState?._id,
      quantity,
      color,
      size,
      uploadedFiles,
      price: productState?.price
    }));
    navigate('/cart');
  };

  const addToWish = (id) => {

    dispatch(addToWishlists(id))
      .then(() => toast.success('Product added to wishlist!'))
      .catch((error) => toast.error(`Failed to add product to wishlist: ${error.message}`));
  };

  const isSingleProductPage = matchPath({ path: '/product/:id', end: true }, location.pathname);

  if (!isSingleProductPage) {
    return null;
  }

  return (
    <div className="bb">
      

                <div className='mt-10 d-flex align-items-center'>
                  <div onClick={() => addToWish(productState?._id)} className='addwish-ic d-flex align-items-center gap-10 icon-link'>
                    <FaRegHeart className='fs-cart2' />
                  </div>
                </div>

                <div className='mt-10 d-flex align-items-center'>
                  <a href="tel:+256 704116349">
                    <MdLocalPhone className='fs-cart3' />
                  </a>
                </div>

      <button 
        onClick={() => alreadyAdded ? navigate('/cart') : uploadCart()}
        className={`cart-bar ${alreadyAdded ? 'btn-disabled' : ''}`}
        disabled={alreadyAdded || !color || (productState?.category === 'Clothes' && !size)}
      >
        <CiShoppingCart className='fs-cart1' />
        <p>Add to cart</p>
      </button>
    </div>
  );
};

export default CartAddBar;