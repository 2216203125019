import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { productService } from "./productService";
import { createSelector } from "@reduxjs/toolkit";

// Thunks
export const getAllProducts = createAsyncThunk('product/get', async (data, thunkAPI) => {
  try {
    return await productService.getProducts(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message); // Use error.message to ensure serializability
  }
});

export const getAProduct = createAsyncThunk('product/getAProduct', async (id, thunkAPI) => {
  try {
    return await productService.getSingleProduct(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const addToWishlists = createAsyncThunk('product/wishlist', async (prodId, thunkAPI) => {
  try {
    return await productService.addToWishlist(prodId);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const addRating = createAsyncThunk('product/rating', async (data, thunkAPI) => {
  try {
    return await productService.rateProduct(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

// Initial State
const productState = {
  products: [],
  filteredProducts: [],
  searchTerm: '',
  singleProduct: null,
  addToWishlists: null,
  rating: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Slice
export const productSlice = createSlice({
  name: 'product',
  initialState: productState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
      state.filteredProducts = action.payload; // Update filteredProducts whenever products change
    },
    setFilteredProducts: (state, action) => {
      state.filteredProducts = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    filterProducts: (state) => {
      // Filter products based on searchTerm
      const filtered = state.products.filter(product =>
        product.title.toLowerCase().includes(state.searchTerm.toLowerCase())
      );
      state.filteredProducts = filtered;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
        state.filteredProducts = action.payload; // Ensure filteredProducts is updated
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload; // Use the payload which is now serializable
      })
      .addCase(addToWishlists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToWishlists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.addToWishlists = action.payload;
        state.message = 'Product Added to Wishlist!';
      })
      .addCase(addToWishlists.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getAProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleProduct = action.payload; // Corrected property name
        state.message = 'Product Fetched Successfully';
      })
      .addCase(getAProduct.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addRating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.rating = action.payload;
        state.message = 'Rating Added Successfully';
        if (state.isSuccess) {
          toast.success('Rating Added Successfully!')
        }
      })
      .addCase(addRating.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
      });
  }
});

// Selector to get unique categories

export const selectUniqueCategories = createSelector(
  state => state.product.products,
  state => state.category.categories,
  (products, categories) => {
    const categoriesMap = new Map();

    products.forEach(product => {
      if (Array.isArray(product.category)) {
        product.category.forEach(catId => {
          const category = categories.find(c => c._id === catId);
          if (category && !categoriesMap.has(category._id)) {
            categoriesMap.set(category._id, {
              _id: category._id,
              title: category.title || 'No Title',
              images: category.images || []
            });
          }
        });
      }
    });

    return Array.from(categoriesMap.values());
  }
);

export const filterProducts = ({ sort, tags, brand, category, minPrice, maxPrice, searchTerm }) => (dispatch, getState) => {
  const { products } = getState().product;
  let filtered = [...products]; // Create a copy of the products array

  // Apply search term filtering
  if (searchTerm) {
    filtered = filtered.filter(product =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  // Apply tag filtering
  const safeTags = tags || [];
  if (safeTags.length > 0) {
    filtered = filtered.filter(product =>
      product.tags && Array.isArray(product.tags) && safeTags.every(tag => product.tags.includes(tag))
    );
  }



  // Apply brand filtering
  if (brand) {
    filtered = filtered.filter(product => product.brand === brand);
  }

  // Apply category filtering
  if (category) {
    filtered = filtered.filter(product =>
      product.category && Array.isArray(product.category) && product.category.includes(category)
    );
  }




  // Apply minPrice filtering
  if (minPrice !== null && minPrice !== undefined) {
    filtered = filtered.filter(product => product.price >= minPrice);
  }

  // Apply maxPrice filtering
  if (maxPrice !== null && maxPrice !== undefined) {
    filtered = filtered.filter(product => product.price <= maxPrice);
  }

   // Apply sorting
  if (sort) {
    filtered = filtered.sort((a, b) => {
      const isDescending = sort.startsWith('-');
      const key = isDescending ? sort.slice(1) : sort;

      if (key === 'title' || key === 'category' || key === 'brand') {
        // Alphabetical sorting
        if (a[key].toLowerCase() < b[key].toLowerCase()) return isDescending ? 1 : -1;
        if (a[key].toLowerCase() > b[key].toLowerCase()) return isDescending ? -1 : 1;
        return 0;
      } else {
        // Numeric sorting
        return isDescending ? b[key] - a[key] : a[key] - b[key];
      }
    });
  }

  dispatch(setFilteredProducts(filtered));
};

export const { setProducts, setFilteredProducts, setSearchTerm } = productSlice.actions;

export default productSlice.reducer;




/*
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { productService } from "./productService";

export const getAllProducts = createAsyncThunk('product/get', async (data, thunkAPI) => {
  try {
    return await productService.getProducts(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAProduct = createAsyncThunk('product/getAProduct', async (id, thunkAPI) => {
  try {
    return await productService.getSingleProduct(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addToWishlists = createAsyncThunk('product/wishlist', async (prodId, thunkAPI) => {
  try {
    return await productService.addToWishlist(prodId);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addRating = createAsyncThunk('product/rating', async (data, thunkAPI) => {
  try {
    return await productService.rateProduct(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const productState = {
  products: [],
  filteredProducts: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const productSlice = createSlice({
  name: 'product',
  initialState: productState,
  reducers: {
    filterProducts: (state, action) => {
      const searchTerm = action.payload.toLowerCase();
      state.filteredProducts = state.products.filter(product =>
        product.title.toLowerCase().includes(searchTerm)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
        state.filteredProducts = action.payload;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleproduct = action.payload;
        state.message = 'Product Fetched Successfully';
      })
      .addCase(getAProduct.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addToWishlists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToWishlists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.addToWishlists = action.payload;
        state.message = 'Product Added to Wishlist!';
      })
      .addCase(addToWishlists.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addRating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.rating = action.payload;
        state.message = 'Rating Added Successfully';
        if (state.isSuccess) {
          toast.success('Rating Added Successfully!');
        }
      })
      .addCase(addRating.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { filterProducts } = productSlice.actions;

export default productSlice.reducer;*/