import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RiHome4Fill } from "react-icons/ri";
import { FaInternetExplorer } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { CiBoxList } from "react-icons/ci";
import cart from '../images/icon/cart-icon.png';
import { useSelector } from 'react-redux';

const NavbarMobile = () => {
  const authState = useSelector(state => state?.auth);
  const cartState = useSelector(state => state?.auth?.cartProduct) || [];
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const sum = Array.isArray(cartState) ? cartState.reduce((acc, item) => acc + Number(item.quantity), 0) : 0;
    setTotal(sum);
  }, [cartState]);

  const [isOpen, setIsOpen] = useState(false);

  // Toggle the drop-up menu
  const toggleDropUp = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar-mobile">
      <div className="dropup-container">
        {/* Button to toggle drop-up */}
        <button onClick={toggleDropUp} className="dropup-toggle-btn">
          <CiBoxList className='fs-nav3' />
        </button>

        {/* Drop-up menu */}
        <div className={`dropup-menu ${isOpen ? 'active' : ''}`}>
          <ul>
            <li><NavLink to={authState?.user ? '/my-profile' : '/login'}>My Account</NavLink></li>
            <li><NavLink to="/my-orders">My Orders</NavLink></li>
            <li><NavLink to="/Whishlist">My Wishlist</NavLink></li>
            <li><NavLink to="/contact">Contact Us</NavLink></li>
            <li><NavLink to="/about us">About Us</NavLink></li>
            <li><NavLink to="/help">Help</NavLink></li>
            
           


          </ul>
        </div>
      </div>

      <NavLink to='/' className={({ isActive }) => isActive ? 'navbar-mobi active' : 'navbar-mobi'}>
        <RiHome4Fill className='fs-nav'/>
      </NavLink>

      <NavLink to='/product' className={({ isActive }) => isActive ? 'navbar-mobi active' : 'navbar-mobi'}>
        <FaInternetExplorer className='fs-nav2'/>
      </NavLink>

      <NavLink to='/cart' className='display-linkk'>
        <img src={cart} alt='' className='cart-ic' />
        <div className='d-flex align-items-center'>
          <span className='badgee'>{cartState?.length ? cartState.length : 0}</span>
        </div>
      </NavLink>

      <NavLink to={authState?.user ? '/my-profile' : '/login'} className={({ isActive }) => isActive ? 'navbar-mobi active' : 'navbar-mobi'}>
        <IoPersonCircleOutline className='fs-nav4'/>
      </NavLink>
    </div>
  );
};

export default NavbarMobile;